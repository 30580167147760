// 
// _footer.scss
// 

.footer {
    bottom: 0!important;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: fixed;
    right: 0;
    color: $footer-color;
    left: 300px;
    height: $footer-height;
    background-color: $footer-bg;
    border-top: 1px solid var(--#{$prefix}border-color);

    @media (max-width: 991.98px) {
        left: 0;
    }
}
  
// Enlarge menu
body[data-sidebar-size="sm"] {
    .footer {
        left: $sidebar-collapsed-width;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}
