/*
Template Name: Minia - Admin & Dashboard Template
Author: Themesbrand
Version: 2.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Icons Css File
*/

// Plugins
@import "plugins/icons";