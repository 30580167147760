// // Import Tailwind CSS
// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities';
// PrimeNG Theme
@import "~primeng/resources/themes/lara-light-blue/theme.css";

// PrimeNG Core CSS
@import "~primeng/resources/primeng.min.css";

// Font Awesome
@import "~font-awesome/css/font-awesome.min.css";

// PrimeIcons
@import '~primeicons/primeicons.css';

@import "node_modules/@swimlane/ngx-datatable/index.css";
@import "node_modules/@swimlane/ngx-datatable/themes/dark.css";
@import "node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.p-multiselect {
  width: 180px !important;
  font-size: 14px;
}

.ngx-datatable.bootstrap {
  background-color: transparent;
  box-shadow: 0 0 0 #000;
}

.ngx-datatable.scroll-vertical {
  height: 300px;
}

.no-detail-row .datatable-row-detail {
  display: none !important;
}

.ngx-datatable.bootstrap.single-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.single-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.bootstrap.multi-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.multi-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.bootstrap.multi-click-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.multi-click-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.bootstrap.single-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.single-selection
  .datatable-body-row.active:hover
  .datatable-row-group,
.ngx-datatable.bootstrap.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.multi-selection
  .datatable-body-row.active:hover
  .datatable-row-group,
.ngx-datatable.bootstrap.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.multi-click-selection
  .datatable-body-row.active:hover
  .datatable-row-group {
  background-color: red !important;
}

.datatable-body-cell {
  color: #106cc8;
}

.datatable-icon-right {
  text-decoration: none !important;
}

.ngx-datatable.bootstrap .empty-row {
  text-align: center;
  margin-top: 20px;
}

.ngx-datatable.bootstrap .datatable-footer {
  background-color: transparent;
  color: rgb(255, 174, 0);
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  color: #1f6f50;
}

.pager li > a,
.pager li > span {
  border-color: black;
}

.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  background-color: #1f6f50;
  border-color: #1f6f50;
  color: #fff;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  border: 0;
}

.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row.datatable-row-even {
  background-color: rgba(0, 0, 0, 0.025);
}

.ngx-datatable.bootstrap .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
  // border: 1px solid black;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: 5px;
}

.wrapper .aside-container .aside-inner,
.wrapper .aside-container {
  width: 230px;
}
.primary-color {
  color: #fcb43c;
}

.secondary-color {
  background-color: #1f6f50 !important;
}

.text-secondary {
  color: #1f6f50 !important;
}
.secondary-color.btn-block {
  color: #e9ecef !important;
}

ng-select {
  background-color: transparent !important;
}

.ng-select .ng-select-container {
  max-height: 36px !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
@media (max-width: 500px) {
  .btn-primary {
    margin-top: 1rem !important;
  }
}
